import React, {useState} from 'react'
import Link from 'gatsby-link'
import './style.css'
import bg1 from '../../assets/images/resource-bg-1.png'
import bg2 from '../../assets/images/bg-2.png'
// import Timeline from 'react-timeline-semantic-ui';

const SCENARIOS = [
  {
    name: "baseline",
    label: "The Path We've Laid",
    text: "Despite the fact that there are people out there working to change how we face and prepare for death, options are still not available for a vast majority of the population. Death Tech, while promising, is mainly only offering better options for the rich. Even green funerals, which are objectively less expensive than traditional western funerals are grossly marked up. The funeral industry still has its hooks in us and we continue to turn our heads away from death and the aging process. We have greener options and innovation happens in spurts but everything new that could become mainstream is suspiciously priced out of the range for most of us. The suicide rate continues to rise, and the massive wave of baby boomers dying off leaves the funeral companies with fat pockets that they use to further entrench themselves.",
    link: null,
    drivers: ['Runaway Capitalism', 'Wealth Inequality', 'Deteriorated Living, Fear of Death']
  },  
  {
    name: "transformation",
    label: "In Death We Join",
    text: "A general shift away from individualism and back towards collectivism gives birth to the communal death space. Our new hyper eco-friendly death ritual involves a new tradition and way to return our bodies to the earth. Death is celebrated as a way to reconnect with nature.  Every Sunday a ritual occurs where the family and loved ones of the deceased carry their dead up the spiral that surrounds the community recompose site. At the top they place their bodies, which are  then lowered down into the other remains. When we return to the bottom we collect the rich soil which from the fully decomposed where it’s nutrients restore our gardens and begin the process of nourishing new life.",
    link: "/futures/transformative",
    drivers: ['Community', "Sustainability", "Collectivism", "Embracing Death"]
  }, 
  {
    name: "new eq",
    label:  "Faux-ever Ever", 
    text: "Death as a service has arrived - Death tech has created extreme measures to tuck death in the corner. Subscription services that go decades beyond our last breath have quickly become the norm. Deep fake + AI combine to allow for the creation of superficial versions of our loved ones that large percentages of the population interact with after their death. Death tech has quickly created a new trillion dollar market by creating a subscription package that spans generations. Suffice it to say, family reunions just got a whole lot weirder.",
    link: null,
    drivers: ['Runaway Capitalism', "Deep Fake & AI", "Escapism", "Fear of Death" ]
  }, 
  {
    name: "collapse",
    label: "The Second Circle",
    text: "Between income inequality, Climate Change, and the unbridled growth of technology in our lives people are racing for the exit. Our consciousness can now exist after we die in the cloud, but the yearly cost is so high, only the 1% can afford it. The rest of us get in by signing up to work as indentured servants to the rich. Don’t do what they say? They stop paying your subscription fees, otherwise, welcome to your new life.",
    link: "/futures/collapse",
    drivers: ['Runaway Capitalism', 'Climate Change', 'Deteriorated Living Conditions', 'Escapism', 'Technocracy', "Fear of Death"]
  }, 
]
const ScenarioSection = ({x}) => { 
  console.log('this scenario', x)
  return (
    <div className="grid-item selected-scenario section-content">
      <h5>Scenario Brief</h5>
        <h4>{x.label}</h4>
        {x.link && <Link to={x.link}>Read Full Narrative</Link> }
        {/* <h4>{x.name}</h4> */}
        <div>{x.text}</div>
        <h5>Key Indicators</h5>
        <div className="selected-drivers">
            {x.drivers.map((driver)=><div>{driver}</div>)}
        </div>
    </div>
  )
}



const ScenarioIndex = () => {
  const [scenario, setScenario] = useState("baseline")
  
  const showScenario = (x) => {
    setScenario(x)
  }

  const thisScenario = () => {
    const ts = SCENARIOS.filter(x =>x.name == scenario  ) 
    return ts[0];
  }

  return (
  <div className='page-wrapper scenario-home'>
    {/* <img src={bg1} className="bg-1" />
    <img src={bg2} className="bg-2" /> */}

    <div className="grid-item section-content scenario-link-container">
        <div className="scenario-link second-circle-link" onClick={() => showScenario("transformation")}>
          <h2>In Death We Join</h2>
          <h4>Transformation</h4>
        </div>
        <div className="scenario-link second-circle-link second-link-2" onClick={() => showScenario("collapse")}>
          <h2>The Second Circle</h2>
          <h4>Collapse</h4>
        </div>
        <div className="scenario-link second-circle-link second-link-2" onClick={() => showScenario("baseline")}>
          <h2 to="/futures/collapse">The Path We've Laid</h2>
          <h4 to="/futures/collapse">Continuation</h4>
        </div>
        <div className="scenario-link second-circle-link second-link-2" onClick={() => showScenario("new eq")}>
          <h2 to="/futures/collapse">Faux-ever Ever</h2>
          <h4 to="/futures/collapse">New Equilbrium</h4>
        </div>
      
    </div>
      
      <div className="grid-item scenario-next-outer">

        <div className="scenario-write-up">
          <p>
          <span className="editor-note">editor's note:</span>Part of this project was about exploring the outer reaches of where death could go in the future. Not because I think these scenarios are where death is going, or ultimately how our future will play out. But because by studying the far edges of possible outcomes we will be better equipped to handle everything that falls in between.
          </p>
          <p>
            Presented are two short stories that explore two extreme scenarios for death. The first story, 'In Death We Join', explores death if as a society we begin to embrace death as death transforms into a communal experience. Scenario two, "The Second Circle", explores the ramifications of digitally capturing consciousness in a collpsing world and what providing a true second life could mean. 
          </p>
        </div>
        <div className="grid-item scenario-next-wrapper">
          <h2>Ready for some action?</h2>
            <ul>
              <li><Link to="/options">How Do We Break the Loop?</Link></li>
              <li><Link to="/table-of-contents">Home</Link></li>
            </ul>
        </div>
      </div>
      {scenario && (
        <ScenarioSection x={thisScenario()} />
        ) 
      }

  </div>
)}

export default ScenarioIndex
